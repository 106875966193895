import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Dashbot Privacy Policy`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/legal/privacy"
      }}>{`https://www.dashbot.io/legal/privacy`}</a></p>
    <p>{`*`}<strong parentName="p">{`*Dashbot Privacy Policy**`}</strong></p>
    <p>{`This Privacy Policy was last updated on April 14`}<sup>{`th`}</sup>{`, 2020.`}</p>
    <p>{`Dashbot Inc. (“Dashbot”, “we”, “our”, or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, shared, and disclosed by Dashbot with respect to your access and use of Dashbot’s services, whether through our website, bot application, or SDK (our “Service”).`}</p>
    <p>{`By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use and disclosure of your personal information in accordance with this Privacy Policy.`}</p>
    <p>{`For information about how we collect and use information about our customers’ end users (“Customer Data”), please see the section below headed “Customer Data”.`}</p>
    <p>{`For the purposes of EU data protection laws (“EU Data Protection Law”), Dashbot is a data controller (i.e., the company which is responsible for, and controls the processing of, your personal information).`}</p>
    <ol>
      <li parentName="ol">{`*`}<strong parentName="li">{`*Information We Collect**`}</strong></li>
    </ol>
    <p>{`The categories of information we collect include:`}</p>
    <ul>
      <li parentName="ul">{`*`}<strong parentName="li">{`*Information you provide to use directly.**`}</strong>{` We may collect certain personal information you provide to us in relation to your use of our Service, such as when you create an account with our Service, sign up for our mailing list, or otherwise communicate with us by sending us an email or posting information or other content to the Service, and we may also collect any communications between you and Dashbot and any other information you provide to Dashbot. In general, we may collect personal information that can identify you such as your name, phone number, payment information, and email address. We may also collect other information that does not directly identify you such as gender, birthday, password, and zip code.`}</li>
      <li parentName="ul">{`*`}<strong parentName="li">{`*Information we receive from third parties.**`}</strong>{` From time to time, we may receive information about you from third parties and other users, and we may collect personal information that you have provided to a third-party social networking site (“SNS”) if you create your account via the SNS.`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*How We Use Cookies and Other Tracking Technology to Collect Information**`}</strong></li>
    </ol>
    <p>{`We, and our third-party partners, automatically collect certain types of usage information when you visit our Service, read our emails, or otherwise engage with us. We typically collect this information through a variety of tracking technologies, including cookies, web beacons, embedded scripts, location-identifying technologies, local storage, file information, and similar technology (collectively, “tracking technologies”).`}</p>
    <p>{`For example, we may automatically collect information such as your location/GPS coordinates, and information about your device and its software, such as your MAC address, Internet Protocol (IP) address, browser type, Internet service provider, platform type, device type, operating system, date and time stamp (a unique ID that allows us to uniquely identify your browser, mobile device, or your account), and other such information. We also collect information about the way you use our Service, for example, the site from which you came and the site to which you are going when you leave our website, the pages you visit, the links you click, how frequently you access the Service, whether you open emails or click the links contained in emails, whether you access the Service from multiple devices, and other actions you take on the Service.`}</p>
    <p>{`When you access our Service from a mobile device, we may collect unique identification numbers associated with your device or our mobile application (including, for example, a UDID, Unique ID for Advertisers (“IDFA”), Google AdID, or Windows Advertising ID), mobile carrier, device type, model and manufacturer, mobile device operating system brand and model, phone number, and, depending on your mobile device settings, your geographical location data, including GPS coordinates (e.g., latitude and/or longitude) or similar information regarding the location of your mobile device, or we may be able to approximate a device’s location by analyzing other information, like an IP address. We may collect analytics data or use third-party analytics tools such as Google Analytics to help us measure traffic and usage trends for the Service and to understand more about the demographics of our users. You can learn more about Google’s practices at `}<a parentName="p" {...{
        "href": "http://www.google.com/policies/privacy/partners"
      }}>{`http://www.google.com/policies/privacy/partners`}</a>{` and view its currently available opt-out options at `}<a parentName="p" {...{
        "href": "https://tools.google.com/dlpage/gaoptout"
      }}>{`https://tools.google.com/dlpage/gaoptout`}</a>{`. We may also work with third-party partners to employ technologies, including the application of statistical modelling tools, which permit us to recognize and contact you across multiple devices. Although we do our best to honor the privacy preferences of our users, we are unable to respond to Do Not Track signals set by your browser at this time.`}</p>
    <p>{`If you would prefer not to accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) disable existing cookies; or (iii) set your browser to automatically reject cookies. Please note that doing so may negatively impact your experience using the Service, as some features and services on our Service may not work properly. Depending on your mobile device and operating system, you may not be able to delete or block all cookies. You may also set your email options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether you have accessed our email and performed certain functions with it.`}</p>
    <p>{`We and our third-party partners may also use cookies and tracking technologies for advertising purposes. For more information about tracking technologies, please see the section headed “Third Party Tracking and Online Advertising” below.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*How We Use the Information We Collect**`}</strong></li>
    </ol>
    <p>{`We use the information we collect for the purposes described below:`}</p>
    <ul>
      <li parentName="ul">{`to operate, maintain, and provide to you the features and functionality of the Service;`}</li>
      <li parentName="ul">{`to communicate directly with you, such as to send you email messages, and to respond to your emails, submissions, questions, comments, requests, and complaints and provide customer service. For more information about your communication preferences, see the section headed “Accessing and Modifying Your Information” below;`}</li>
      <li parentName="ul">{`to send you confirmations, updates, security alerts, and support and administrative messages and otherwise facilitate your use of, and our administration and operation of, our services;`}</li>
      <li parentName="ul">{`to personalize your experience using our Service (your information helps us to better serve individual needs);`}</li>
      <li parentName="ul">{`to provide our services or information you request, and to process and complete any transactions;`}</li>
      <li parentName="ul">{`to monitor and analyze usage and trends, and to personalize and improve the Service and your experiences when the Service is used, such as providing content, advertisements or features that match your profile or interests (in accordance with your settings and options), and to increase the functionality and user friendliness of our Service;`}</li>
      <li parentName="ul">{`to perform research and analysis about your use of, or interest in, our products, services, or content, or products, services or content offered by others in order to better serve you and your users’ needs. We may share non-personal and non-identifying information about you and your users with other third parties for industry analysis or other purposes;`}</li>
      <li parentName="ul">{`to develop and display content and advertising tailored to your interests (in accordance with your settings and options). Please see the section headed “Third Party Tracking and Online Advertising” below;`}</li>
      <li parentName="ul">{`to enforce or exercise any rights in our terms and conditions and enforce compliance with laws and law enforcement;`}</li>
      <li parentName="ul">{`to manage our business;`}</li>
      <li parentName="ul">{`to perform functions as otherwise described to you at the time of collection; and`}</li>
      <li parentName="ul">{`for any other purpose for which the information was collected, as notified to you.`}</li>
    </ul>
    <p>{`We use or may use the data collected through tracking technologies to:`}</p>
    <ul>
      <li parentName="ul">{`remember information so that you will not have to re-enter it during your visit or the next time you visit the site;`}</li>
      <li parentName="ul">{`provide custom, personalized content and information, including targeted content and advertising (in accordance with your settings and options). Please see the section headed “Third Party Tracking and Online Advertising” below;`}</li>
      <li parentName="ul">{`recognize and contact you across multiple devices;`}</li>
      <li parentName="ul">{`provide and monitor the effectiveness of our Service;`}</li>
      <li parentName="ul">{`monitor aggregate metrics such as total number of visitors, traffic, usage, and demographic patterns on our Service;`}</li>
      <li parentName="ul">{`diagnose or fix technology problems; and`}</li>
      <li parentName="ul">{`otherwise plan for and enhance our Service.`}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*What Information Do We Share With Third Parties?**`}</strong></li>
    </ol>
    <p>{`We may share your personal information in the instances described below. For further information on your choices regarding your information, see the section headed “Accessing and Modifying Your Information” below.`}</p>
    <p>{`We may share your personal information as described as follows:`}</p>
    <ul>
      <li parentName="ul">{`with advertisers and advertising platforms for the purpose of more targeted advertising;`}</li>
      <li parentName="ul">{`with service providers who are working with us in connection with the operation of our site or our services, which may include preventing fraud or other illegal activity, such as anti-money laundering services, verifying your identity, providing mailing services, providing tax and accounting services, contest fulfilment, web hosting, or providing analytic services (these service providers have access to your personal information only to perform services on our behalf and are obligated not to disclose it or use it for any other purposes);`}</li>
      <li parentName="ul">{`with other companies and brands owned or controlled by Dashbot and other companies owned by or under common ownership as Dashbot, which also includes our subsidiaries (i.e., any organization we own or control) or our ultimate holding company (i.e., any organization that owns or controls us), and any subsidiaries it owns. To the extent that any of these companies use your personal information, they may only do so in the same way as we can under this Privacy Policy; Dashbot does not currently have any subsidiaries;`}</li>
      <li parentName="ul">{`we may share and sell aggregated information and/or non-identifiable information (which does not identify you as an individual) with third parties for industry analysis, advertising, demographic profiling and other similar purposes;`}</li>
      <li parentName="ul">{`with the public, when you provide feedback or user content on our site. For example, if you post user content or feedback on our site, your personal information such as your name, state of residence, and your comments, may appear on our site;`}</li>
      <li parentName="ul">{`when you request us to do so, or give us your consent to do so, including if we notify you that the information you provide will be shared in a particular manner and you provide such information;`}</li>
      <li parentName="ul">{`when we believe in good faith that we are lawfully authorized or required to do so or that doing so is reasonably necessary or appropriate to comply with the law or legal processes or respond to lawful requests or legal authorities, including responding to lawful subpoenas, warrants or court orders;`}</li>
      <li parentName="ul">{`to enforce or apply our Privacy Policy, our Terms of Service or our other policies or agreements; to protect the security or integrity of our Service; and/or to exercise or protect the rights, property, or personal safety of Dashbot, visitors to our site and users of our Service, or others; and`}</li>
      <li parentName="ul">{`with other parties in connection with, or during negotiations of, a company transaction such as a merger, sale of company assets, financing or acquisition, or in any other situation where personal information may be disclosed or transferred as one of our business assets, or in the event of a bankruptcy or related or similar proceedings.`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*Links to Other Sites or Apps**`}</strong></li>
    </ol>
    <p>{`Dashbot may contain links to other sites or apps. Any information you provide on those sites is provided directly to the owner of that site and is subject to that party’s privacy policy. Please note that our linking to these sites, apps, or online services is not an endorsement, authorization, or representation of our affiliation. Our privacy policy does not apply to such sites or apps, and we are not responsible for the content or privacy and security practices and policies of those sites or apps.`}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*Accessing and Modifying Your Information**`}</strong></li>
    </ol>
    <p>{`If you have an account on our Service, you can access and modify the personal information you provide to us which is associated with your account, as follows:`}</p>
    <ul>
      <li parentName="ul">{`*`}<strong parentName="li">{`*Profile Settings.**`}</strong>{` You may view your profile information, such as your user name, on your account’s settings page. You may change your profile information by emailing us at `}<a parentName="li" {...{
          "href": "mailto:support@dashbot.io"
        }}>{`support@dashbot.io`}</a>{`.`}</li>
      <li parentName="ul">{`*`}<strong parentName="li">{`*How to control your communications preferences.**`}</strong>{` You can stop receiving promotional email communications from us by clicking on the “unsubscribe link” provided in such communications, or by changing your account preferences on the Service. We make every effort to promptly process all unsubscribe requests. If you opt-out of these messages, you will still receive emails from us about your account, and other pertinent information related to your use of the Service (e.g., account verification, transactional communications, changes/updates to features of the Service, technical and security notices). These communications are considered part of the Service and your account, which you cannot opt-out from receiving.`}</li>
      <li parentName="ul">{`*`}<strong parentName="li">{`*Modifying or deleting your information.**`}</strong>{` If you have any questions about reviewing, modifying, or deleting your information, or if you want to remove your name or comments from our Service or publicly displayed content, you can contact us directly at `}<a parentName="li" {...{
          "href": "mailto:support@dashbot.io"
        }}>{`support@dashbot.io`}</a>{`. We will take steps to delete your personally identifiable information as soon as is practicable, but we may not be able to modify or delete your information in all circumstances, and some information may remain in archived/backup copies for our records and as otherwise required by law.`}</li>
    </ul>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*What Steps Do We Take to Store and Protect Your Information?**`}</strong></li>
    </ol>
    <p>{`*`}<strong parentName="p">{`*Keeping your information safe**`}</strong>{`: We care about the security of your information and employ reasonable physical, administrative, and technological safeguards designed to preserve the integrity and security of all information collected through our Service. Please be aware, however, that despite our efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed against any interception or other type of misuse. Although we have implemented these precautions, we cannot ensure or warrant the security of your personal information. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and, where appropriate, notify those individuals whose information may have been compromised and take other steps, in accordance with any applicable laws and regulations.`}</p>
    <p>{`*`}<strong parentName="p">{`*Data storage and transfer**`}</strong>{`: Your information collected through our website may be stored and processed in the United States or any other country in which Dashbot or its affiliates or service providers maintain facilities. If you are located in the European Union or other regions with laws governing data collection and use that may differ from U.S. law, please note that we may transfer information, including personal information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction, and we will take all steps reasonably necessary to ensure that any personal information is treated securely and in accordance with this Privacy Policy. Such transfers are made pursuant to appropriate safeguards. If you wish to enquire further about these transfers, please contact us using the details set out at the end of this Privacy Policy.`}</p>
    <p>{`For individuals based in the EU or Switzerland, we store personal information for as long as necessary to fulfill the purposes for which we collect the data, see the sections headed “Information we Collect” and “How We Use Cookies and Other Tracking Technology to Collect Information” above, unless otherwise required by law.`}</p>
    <p>{`*`}<strong parentName="p">{`*Privacy Shield.**`}</strong>{` We comply with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework (collectively, “Privacy Shield”) as set forth by the U.S. Department of Commerce regarding the collection, use and retention of personal information received from European Union countries and Switzerland.`}</p>
    <p>{`We have certified that we adhere to the Privacy Principles of notice, choice and accountability for onward transfer, security, data integrity, purpose limitation, access, and recourse, enforcement and liability (“Principles”). If there is any conflict between the policies in this Privacy Policy and the Privacy Shield Principles, the Principles shall govern. To learn more about Privacy Shield, please visit the U.S. Department of Commerce Privacy Shield website: `}<u><a parentName="p" {...{
          "href": "https://www.privacyshield.gov/"
        }}>{`https://www.privacyshield.gov/`}</a></u>{`. For more information regarding our Privacy Shield certification, please see: `}<u><a parentName="p" {...{
          "href": "https://www.privacyshield.gov/list"
        }}>{`https://www.privacyshield.gov/list`}</a></u>{`.`}</p>
    <p>{`If you wish to enquire further about the safeguards we use, please contact us using the details set out at the end of this Privacy Policy.`}</p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*For Residents in the EU and Switzerland**`}</strong></li>
    </ol>
    <p>{`*`}<strong parentName="p">{`*Your Rights.**`}</strong>{` If you are located in the EU or Switzerland, you have the following rights in respect of your personal information that we hold:`}</p>
    <ul>
      <li parentName="ul">{`Right of access. The right to obtain access to your personal information;`}</li>
      <li parentName="ul">{`Right to rectification. The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete;`}</li>
      <li parentName="ul">{`Right to erasure. The right to obtain the erasure of your personal information without undue delay in certain circumstances, such as where the personal information is no longer necessary in relation to the purposes for which it was collected or processed;`}</li>
      <li parentName="ul">{`Right to restriction. The right to obtain the restriction of the processing undertaken by us on your personal information in certain circumstances, such as where the accuracy of the personal information is contested by you, for a period enabling us to verify the accuracy of that personal information;`}</li>
      <li parentName="ul">{`Right to portability. The right to portability allows you to move, copy or transfer personal information easily from one organization to another; and`}</li>
      <li parentName="ul">{`Right to object. You have a right to object to processing based on legitimate interests and direct marketing.`}</li>
    </ul>
    <p>{`If you wish to exercise one of these rights, please contact us using the contact details at the end of this Privacy Policy.`}</p>
    <p>{`You also have the right to lodge a complaint to your local data protection authority. Further information about how to contact your local data protection authority is available at `}<a parentName="p" {...{
        "href": "http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
      }}>{`http://ec.europa.eu/justice/data-protection/bodies/authorities/index`}{`_`}{`en.htm`}</a>{`.`}</p>
    <p>{`As part of our participation in the Privacy Shield, we are subject to the investigatory and enforcement powers of the US Federal Trade Commission (“FTC”).`}</p>
    <p>{`In compliance with the EU-US Privacy Shield Principles, we commit to resolve complaints about your privacy and our collection or use of your personal information. European Union residents with inquiries or complaints regarding this Privacy Policy should first contact us using the contact information listed below.`}</p>
    <p>{`We have further committed to refer unresolved privacy complaints under the Privacy Shield Principles to an independent dispute resolution provider based in the US, JAMS (Judicial Arbitration and Mediation Services, Inc.). Under certain conditions specified by the Principles, you may also be able to invoke binding arbitration to resolve your complaint. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed, please visit `}<a parentName="p" {...{
        "href": "https://www.jamsadr.com/eu-us-privacy-shield"
      }}>{`https://www.jamsadr.com/eu-us-privacy-shield`}</a>{` for more information and to file a complaint. The services of JAMS are provided at no cost to you. In certain circumstances, the Privacy Shield Framework provides the right to invoke binding arbitration to resolve complaints not resolved by other means, as described in Annex I to the Privacy Shield Principles, available at `}<a parentName="p" {...{
        "href": "https://www.privacyshield.gov/article?id=ANNEX-I-introduction"
      }}>{`https://www.privacyshield.gov/article?id=ANNEX-I-introduction`}</a>{`.`}</p>
    <p>{`We may transfer personal information to certain third parties, as set forth in this Privacy Policy. Where the transfer is to a third-party agent acting on our behalf, we may be liable if such third parties fail to meet their obligations, and we are responsible for the event giving rise to the damage.`}</p>
    <p>{`*`}<strong parentName="p">{`*Legal basis for processing.**`}</strong>{` In the EU, the purposes for which we process your personal information are:`}</p>
    <ul>
      <li parentName="ul">{`The provision of personal information by you may be necessary for the performance of any contractual relationship we have with you;`}</li>
      <li parentName="ul">{`Where it is necessary for compliance with our legal obligations laid down by EU law;`}</li>
      <li parentName="ul">{`Where in our legitimate interests (provided these are not overridden by your interests and fundamental rights and freedoms – this includes our own legitimate interests and those of other entities and branches in our group of companies) such as: `}
        <ul parentName="li">
          <li parentName="ul">{`to contact you and respond to your requests and enquiries;`}</li>
          <li parentName="ul">{`for business administration, including statistical analysis;`}</li>
          <li parentName="ul">{`to provide the Services to you;`}</li>
          <li parentName="ul">{`for fraud prevention and detection; and`}</li>
          <li parentName="ul">{`to comply with applicable laws, regulations or codes of practices.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`We may also process your personal information on the basis of your freely given, specific, informed and unambiguous consent. You should be aware that you are entitled under EU Data Protection Law to withdraw your consent where that has been given, at any time. If you do this and we have no alternative lawful reason to process your personal information, this may affect our ability to provide you with rights to use the Service.`}</p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*International Users Outside the US, EU and Switzerland.**`}</strong></li>
    </ol>
    <p>{`If you are located outside the United States, EU and Switzerland, and you choose to provide information to us, we may transfer your information to the United States and process it there (or any other country where we operate or may have co-locations). By using Dashbot, you consent to the collection, transfer, use, storage and disclosure of your information as described in this privacy policy.`}</p>
    <p>{`When you provide personal information, the information may be sent to servers located in the US and other countries around the world.`}</p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*Customer Data**`}</strong></li>
    </ol>
    <p>{`Our obligations with respect to Customer Data are defined in our agreements with Dashbot’s customers and are not included in this Privacy Policy. Each customer will remain responsible for the privacy and security of the Customer Data that it collects and processes and for compliance with applicable data protection laws that may apply to the collection, processing and disclosure of Customer Data through the Service.`}</p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*Children’s Online Privacy Protection Act Compliance**`}</strong></li>
    </ol>
    <p>{`Our service is not directed to children under 13 and we do not knowingly collect personal information from children under 13. If we learn that we have collected personal information of a child under 13, we will take steps to delete such information from our files as soon as possible.`}</p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`*`}<strong parentName="li">{`*Changes**`}</strong></li>
    </ol>
    <p>{`Any information that is collected is subject to the Privacy Policy in effect at the time such information is collected. We may modify and revise our Privacy Policy from time-to-time. If we make any material changes to this policy, we will notify you of such changes by posting them on the Service or by sending you an email or other notification, and we will indicate when such changes will become effective. By continuing to access or use the Service after those changes become effective, you are agreeing to be bound by the revised Privacy Policy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      